.items{
  padding-left: 200px;
  :global {
    .ant-descriptions-item-label{
      width: 150px;
      color: #333;
      vertical-align: top;
      text-align: right;
      display: inline-block;
      margin-right: 10px;
    }
    .ant-descriptions-item > span{
      color: #333;
    }
  }
}

@primary-color: #0f8605;@border-radius-base: 2px;
.banner{
  height: 450px;
  display: flex;
  background: url('~aliasimgurl/assess_banner.png') no-repeat top center;
  background-size: cover;
  //background-attachment: fixed;
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .leftWarp{
        height: 100%;
        color: #fff;
        .title{
          line-height: 56px;
          font-size: 38px;
          margin: 100px 0 32px 0 ;
        }
        .subtitle{
          line-height: 32px;
            font-size: 18px;
        }

    }
    .rightWarp{
      width: 400px;
      padding: 20px;
      background: #fff;
      border-radius: 10px;
      .formTitle{
        text-align: center;
        font-size: 18px;
        color: #32CD9B;
        margin-top: 10px ;
        margin-bottom:40px;
      }
      .form{
        :global{
          .ant-input-suffix{
            color: #999;
          }

        }
      }
    }
  }

}



.indexWarp{
  .serviceList{
    display: flex;
    justify-content: space-around;
    padding-top: 32px;
    .service{
      width: 250px;
      height: 290px;
      background: #fff;
      text-align: center;
      padding: 40px 27px;
      box-shadow: 0px 5px 10px 0px rgba(221, 221, 221, 0.2);
        img{
          width: 44px;
          height: 46px;
          margin:auto;
        }
        .title{
          color: #333;
          font-size: 16px;
          margin-top: 30px;
          margin-bottom: 10px;
        }
      .subtitle{
        text-align: left;
        font-size: 14px;
        line-height: 26px;
        font-family: Microsoft YaHei;
        color: #999999;
      }
    }
  }

  .flow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    .flowItem{
      cursor: pointer;
      .iconIndex{
        width: 80px;
        height: 80px;
        background: #F5F5F5;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin:auto;
        .icon{
          width: 36px;
          height: 36px;
          background-size: 100% 100%;
        }
      }
      .flowTitle{
        text-align: center;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #444;
        line-height: 25px;
        margin-top: 20px;
      }
      &:hover{
        .iconIndex{
          background: linear-gradient(-63deg, #62D9A0, #35D7A4);
          box-shadow: 0px 6px 12px 0px rgba(53, 215, 164, 0.34);
        }
        .flowTitle{
          color: #32CD9B;
        }
      }
    }
    .flowItem:nth-of-type(1){
        .icon{
          background: url("~aliasimgurl/flow01@green.png");
        }
        &:hover{
          .icon{
            background: url("~aliasimgurl/flow01@select.png");
          }

        }
    }
    .flowItem:nth-of-type(3){

      .icon{
        background: url("~aliasimgurl/flow02@green.png");
      }
      &:hover{
        .icon{
          background: url("~aliasimgurl/flow02@select.png");
        }

      }
    }
    .flowItem:nth-of-type(5){
      .icon{
        background: url("~aliasimgurl/flow03@green.png");
      }
      &:hover{
        .icon{
          background: url("~aliasimgurl/flow03@select.png");
        }

      }
    }
    .flowItem:nth-of-type(7){
      .icon{
        background: url("~aliasimgurl/flow04@green.png");
      }
      &:hover{
        .icon{
          background: url("~aliasimgurl/flow04@select.png");
        }

      }
    }
    .flowItem:nth-of-type(9){
      .icon{
        background: url("~aliasimgurl/flow05@green.png");
      }
      &:hover{
        .icon{
          background: url("~aliasimgurl/flow05@select.png");
        }

      }
    }
    .flowIcon{
      width: 68px;
      height: 10px;
      background: url('~aliasimgurl/expert_flow_icon.png');
      background-size: 100% 100%;
      margin-bottom: 44px;
    }
  }

  .contact{
    padding: 42px;
      background: url('~aliasimgurl/assess_banner_01.png') no-repeat top center;
      background-size: cover;
      .contactTitle{
        color: #32CD9B;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        &:after{
          content:'';
          height: 2px;
          width: 84px;
          background: #32CD9B;
          margin:auto;
          display: block;
          margin-top: 6px;

        }
      }
      .form{
        padding: 38px 116px 0 116px ;
        background: #fff;
        border-radius: 10px;
        display: flex;
        margin-top: 52px;
        margin-bottom: 50px ;
        .formLeft,.formRight{
          flex: 1;
        }
        .formLeft{
          margin-right: 60px;
        }
      }
      :global {
        .ant-form-item-label > label {
          color: #32CD9B
        }
        .ant-form-item-extra{
          color:#CCCCCC;
          font-size: 12px;
        }
        .ant-input:hover,
        .ant-input:focus,
        .ant-input-affix-wrapper:hover,
        .ant-picker:hover,
        .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus + .ant-checkbox-inner {
          border-color: #32CD9B;
        }
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector,.ant-select:not(.ant-select-disabled):focus .ant-select-selector{
          border-color: #32CD9B;
        }
      }
  }
}

.btn{
  width: 170px;
  line-height: 38px;
  text-align: center;
  font-size: 16px;
  color:#fff;
  padding: 1px;
  background: linear-gradient(-63deg, #62D9A0, #35D7A4);
  box-shadow: 0px 6px 12px 0px rgba(53, 215, 164, 0.34);
  border-radius: 19px;
  margin: 34px auto 0 auto;
  cursor: pointer;
  &:hover{
    padding: 0;
    color: #32CD9B;
    border: 1px solid  #32CD9B;
    background: initial;
  }
}
.assessBtn{
  width: 100%;
  line-height: 38px;
  text-align: center;
  font-size: 16px;
  color:#fff;
  padding: 1px;
  background: #32CD9B;
  box-shadow: 0px 6px 12px 0px rgba(53, 215, 164, 0.34);
  border-radius: 5px;
  margin: 36px auto 8px auto;
  cursor: pointer;
  &:hover{
    padding: 0;
    color: #32CD9B;
    border: 1px solid  #32CD9B;
    background: initial;
  }
}
.btns{
  text-align: center;
  margin-top: 10px;
}

.viewWrap{
  min-height: 800px;
  padding: 30px 0;
  margin-bottom: 80px;

  .titleInfo{
    text-align: center;
    border-bottom: 1px solid #eee;
    .viewTitle{
      font-size: 22px;
      color:#000;
    }
    p{
      font-size: 14px;
      color:#a5a4a4;
      margin-top: 10px;
      .text{
        vertical-align: middle;
      }
      > span{
        line-height: 20px;
        margin-right: 35px;
        &:last-child{
          margin-right: 0;
        }
      }

    }
    .check{
      color: #067562;
    }
  }
  .desInfo{
    width: 100%;
    padding: 20px;
    font-size: 14px;
    line-height: 24px;
    color: #000;
    box-sizing: border-box;
    overflow: hidden;
    img{
      max-width: 100%;
    }
  }

  :global{
    .anticon{
      font-size: 16px;
      margin-right: 5px;
      vertical-align: middle;
    }
  }
}


.bodyWarp{
  display: flex;
  margin-top: 24px;
  .leftWarp{
    width: 810px;
    margin-right: 30px;
    .user{
      display: flex;
        margin-bottom: 40px;
      .avatar{
        width: 118px;
        height: 133px;
        vertical-align: top;
        margin-right: 24px;
      }
      .userInfo{
        flex: 1;
        color: #666666;
        .name{
          color: #000;
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
    }
    .userList{
        .item{
          padding: 18px 0;
          border-bottom: 1px solid #F5F5F5;
            .title{
              display: flex;
              justify-content: space-between;
              color: #333;
              font-size: 16px;
              line-height: 17px;
              margin-bottom: 10px;
              .time{
                color : #888;
                width: 120px;
                text-align: right;
                flex: none;
              }
            }
          .des{

          }
        }
    }
    .listWrap{
      flex:1;
      overflow: hidden;
      background:#fff;
      margin-bottom: 20px;
      .tabs{
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        padding: 15px 0;
        border-bottom: 1px solid #DDDDDD;
        .tab{
          position: relative;
          padding: 0 18px;
          cursor: pointer;
        }
        .active{
          font-weight: bold;
          color: #1D8526;
          .border{
            position: absolute;
            left: 50%;
            bottom: -3px;
            height: 2px;
            border-radius: 1px;
            background: #1D8526;
            width: 20px;
            transform: translate(-50%,0);
          }
        }
      }
      .title{
        padding-bottom: 20px;
        border-bottom: 1px solid #ddd;
        i{
          font-style: normal;
          margin: 0 10px;
          font-size: 16px;
          cursor: pointer;
          &.active{
            color: #1FAE94;
          }
        }
      }
    }

  }
  .rightWarp{
    width: 330px;
    .bannerWarp{
      cursor: pointer;
      margin-top: 30px;
      img{
        height: 247px;
        width: 100%;
      }
    }
  }
}



@primary-color: #0f8605;@border-radius-base: 2px;
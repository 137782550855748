.list{
  height: 284px;
  .swiper{
    width: 331px;
    height: 206px;
    img{
      width: 331px;
      height: 206px;
      cursor: pointer;
      margin-left: -1px;
    }
    .content{
        height: 80px;
        padding: 15px ;
        background: #F5F5F5;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2 ;
        text-overflow:ellipsis;
        cursor: pointer;
        font-size: 14px;
        &:hover{
          text-decoration: underline;
        }
      .tag{
        display: inline-block;
        width: 43px;
        line-height: 21px;
        text-align: center;
        color : #fff;
        background: #1A9825;
        margin-right: 5px;
        border-radius: 2px;
      }
    }
    :global{
      .swiper-button-next:after, .swiper-button-prev:after{
        font-size: 30px;
      }
      .swiper-button-next,.swiper-button-prev{
        color:#fff;
        font-size: 20px;
        font-weight: normal;
      }
      .swiper-button-prev{
        margin-left: 10px;
      }
      .swiper-button-next, .swiper-button-prev{
        top: calc(50% - 30px)
      }
    }
  }

}
@primary-color: #0f8605;@border-radius-base: 2px;
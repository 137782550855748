.card {
  border:1px solid rgba(246, 246, 246, 1);
  border-radius: 5px;
  .title{
    background: #F6F5F8;
    line-height: 42px;
    font-size: 16px;
    padding: 0 13px;
    color: #056152;
  }
  .list{
    padding: 0 15px 15px 15px ;
    .item {
      font-size: 14px;
      display: flex;
      padding-top: 10px;

      .name {
        overflow: hidden;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 20px;
        width: 65px;
        margin-right: 10px;
        text-align: right;

      }
      .numberWarp{
        flex: 1;
        text-align: center;
        .number{
          color: #fff;
          white-space: nowrap;
          line-height: 20px;

        }
      }

    }

  }

}
@primary-color: #0f8605;@border-radius-base: 2px;
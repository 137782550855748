.list{
  padding: 10px 15px;
  height: 248px;
    .item{
        display: flex;
        padding: 8px 0;
        font-size: 14px;
        color:#333;
        cursor: pointer;
        .title{
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;

        }
      &:hover{
        .title{
          color: #1D8526;
        }

      }
    }
  .territory{
    font-size: 16px;
    color:#000;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    .ext{
        display: none;
        width: 428px;
        position: absolute;
        left: -15px;
        z-index: 1000;
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        padding: 0 10px 30px 20px;
        background: RGBA(0, 0, 0, 0.7);
      .extBox{
        display: flex;
        flex-wrap: wrap;
        span{
          padding: 0 12px;
          border-right: 1px solid #fff;
          margin-top: 25px;
          cursor: pointer;
          &:hover{
            color: #1A9825;
          }
        }
        span:nth-of-type(7n+7){
          border: 0;
        }
        span:last-child{
          border: 0
        }
      }


    }
    &:hover{
      .ext{
        display: block;
      }
    }
  }
  :global{
    .ant-statistic-content{
      font-size: 13px;

    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;

.body{
  flex: 1;
  overflow: hidden;
  .searchBox{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .typeBox{
      display: flex;
      &>div{
        position: relative;
        line-height: 34px;
        font-size: 16px;
        color: #666;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        padding: 0 7px;
        background: #F7F7F7;
        margin-right: 25px;
        &:after{
          content: '';
          position: absolute;
          right: -12px;
          top: 0;
          background: #EEEEEE;
          height: 36px;
          width: 1px;
        }
        &:last-child:after{
          width: 0;

        }
      }
      .active{
        background: #5AB2A2;
        color: #fff;
      }
    }
  }


  .row{
    display: flex;
    border-bottom: 1px solid #eee;
    font-size: 16px;
    &:first-child{
      border:0;
    }
    .name{
      width: 400px;
      line-height: 50px;
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
      &:hover{
        color: #077462;
      }
    }
    .time{
      width: 185px;
      line-height: 50px;
      text-align: center;
    }
    .status{
      color: #5699E5;
      line-height: 50px;
      text-align: center;
      flex: 1;
    }
    .operation{
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn{
        width: 83px;
        line-height: 27px;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        background: #FCB02A;
        color: #fff;
        &:hover{
          background: #ffe51a;
        }
      }
    }
  }
  .header{
    margin: 0 37px 0 0;
    .name{
      font-weight: normal;
      text-align: center;
      cursor: default;
      &:hover{
        color: inherit;
      }
    }
    .status{
      color: inherit;
    }
  }
  .list{
    padding: 0 20px 0 0;
    max-height: 600px;
    overflow-y: scroll;
    //position: relative;
  }
  :global{
    .ant-btn-primary{
      background: #5AB2A2;
      border-color: #5AB2A2;
    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
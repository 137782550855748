.cardbox{
    background: #fff;
    border-radius: 2px;
    height: 100%;
    .title{
        padding: 3px 0 3px 14px;
        font-size: 18px;
        font-weight: bold;
        color:#000;
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 10px;
        &:before{
            position:absolute;
            top:50%;
            left: 0;
            content: '';
            width: 4px;
            height: 18px;
            background: #1E6851;
            margin-top: -8px;
        }

    }
}
@primary-color: #0f8605;@border-radius-base: 2px;
.swiper{
  width: 485px;
  height: 314px;
  img{
    width: 100%;
    height: 314px;
  }
  :global{
    .swiper-button-next:after, .swiper-button-prev:after{
      padding-left: 5px;
      font-size: 30px;
    }
    .swiper-button-next,.swiper-button-prev{
      color:#fff;
    }
    .swiper-pagination-bullet{
      background: #999;
      opacity: .7
    }
    .swiper-pagination-bullet-active{
      color:#fff;
      background: #fff;
      opacity: 1
    }
  }
}
.switchBox{
  display: flex;
  margin-top: 14px;
  img{
    width: 84px;
    height: 56px;
    display: block;
    margin-right: 10px;
    cursor: pointer;
    &:last-child{
      margin-right: 0;
    }
  }
  .active{
    border: 2px solid #ff9f00;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
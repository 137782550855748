.searchWarp{
  position: relative;
  .tabs{
    display: flex;
    //position: absolute;
    //top: -28px;
    .tab{
      font-size: 13px;
      color: #333;
      line-height: 28px;
      padding: 0 10px;
      cursor: pointer;
      background: #eee;
      margin-right: 2px;
    }
    .active{
      background: #2A8C33;
      color: #fff;
    }
  }
  :global{
    .ant-input-lg{
      font-size: 14px;
    }
    .ant-input-affix-wrapper{
      height: 34px;
      border-color: #2A8C33;
    }
    .ant-btn-primary{
      height: 34px;
      font-size: 15px;
    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
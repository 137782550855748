.list{
    margin-top: 30px;
    //background:#fff;
    display: flex;
    justify-content: space-between;
    .item{
        font-size: 16px;
        cursor: pointer;

    }
    .swiper{
      width: 580px;
      height: 314px;
      img{
        width: 100%;
        height: 314px;
      }
      :global{
        .swiper-pagination-bullet{
          background: #999;
          opacity: .7
        }
        .swiper-pagination-bullet-active{
          color:#fff;
          background: #fff;
          opacity: 1
        }
      }

    }
    .leftBox{
    }

  :global{
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
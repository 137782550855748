.box{
  height: 372px;
  overflow: hidden;
  background: #fff;
  padding: 15px;
    .item{
        display: flex;
        font-size: 18px;
        color:#333;
        cursor: pointer;
        margin-bottom: 13px;
      >img{
        width: 166px;
        height: 106px;
        display: block;
        margin-right: 18px;
      }
        .right{
          position: relative;
          flex: 1;
          padding: 10px 0;
          .title{
            font-size: 14px;
            line-height: 20px;
            color: #000;
            word-break:break-all;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2 ;
            text-overflow:ellipsis;
          }
          .footer {
            position: absolute;
            bottom: 10px;
            left: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 14px;
            color: #BFBFBF;
          }
        }

        &:hover{
          .title{
            color: #1D8526;
          }

        }
      &:last-child{
        margin-bottom: 0;
      }
    }
  :global{
    .ant-statistic-content{
      font-size: 13px;

    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
.main-nav{
  border-top: 3px solid #1D8526;
  background: #fff;
  box-shadow:0px 2px 6px 0px rgba(3,0,1,0.07);
  z-index: 100;
  .navList{
    display: flex;
    justify-content: space-between;
    line-height: 48px;
      .nav{
          //margin-right: 30px;
          font-size: 18px;
          color:#000;
        &.selected,&:hover{
            color: #1D8526;
          }
          >div{
              text-align: center;
          }
        &:last-child{
          margin-right: 0;
        }
      }
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;
.box{
   background: #fff;
    margin-bottom: 30px;
    .banner{
        display: block;
        width: 100%;
    }
  .content{
    position: relative;
    padding: 36px 100px 0 36px;
    background: #fff;
    .form{
      padding-left: 100px;
    }
  }
  .ext{
    position: absolute;
    bottom: 200px;
    right: 400px;
    a{
      color: #4f9dfb;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .trigger {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .btn {
      width: 160px;
      line-height: 37px;
      color: #fff;
      font-size: 18px;
      text-align: center;
      cursor: pointer;
      background: linear-gradient(-63deg, #62D9A0, #35D7A4);
      box-shadow: 0px 6px 12px 0px rgba(53, 215, 164, 0.34);
      border-radius: 19px;
      &:hover{
        background: #32cd9b;
      }
    }
    .backBtn{
      color: #333;
      background: #F5F5F5;
      margin-right: 30px;
      box-shadow: none;
      &:hover{
        color: #333;
        background: #eee;
      }
    }
    .prePage{
      width: 160px;
      height: 42px;
      font-size: 18px;
      color: #1DBC86;
      background: #fff;
      border: 1px solid #1DBC86;
      margin-right: 30px;
      &:hover {
        color: #067562;
        background: #fff;
        border: 1px solid #067562;
      }
      &:last-child{
        margin-right: 0;
      }
    }
    .saveBtn{
      background: #EFC740;
      color: #fff;
      border: 0;
      margin: 0 20px;
      &:hover{
        color: #fff;
        border-color: #EFC740;
      }
    }
  }
  .codeBtn{

    background: #1DBC86;
    border:0;
    &:hover{
      background: #067562;
    }
  }


  .pactionText{
    color: #333;
    margin-bottom: 5px;
  }
  .status_img{
      width: 60px;
      height: 60px;
  }
  .result{
    text-align: center;
    padding-bottom: 80px;
    color: #32CD9B;
    .title{
      font-weight: bold;
      font-size: 16px;
      margin-top: 22px;
    }
    .subtitle{
      font-size: 14px;
      color: #B8B7B7;
      margin-top: 8px;
    }
    .remark{
      margin-top: 10px;
      color: #666666;
      .label{
        color: #333;
      }
    }
  }
  .row{
    position: relative;
    .hint{
      position: absolute;
      left: 655px;
      top: 5px;
    }
  }


  :global {
    .ant-tabs-nav {
      display: none !important;
    }
    .ant-form-item{
      margin-bottom:18px;
    }
    .ant-form-item textarea.ant-input{
      min-height: 100px;
    }
    .ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split{
      font-size: 12px;
    }

    .ant-input:hover,.ant-input:focus,.ant-upload:hover,.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
      border-color: #1DBC86;
    }
    .ant-btn:hover,.ant-btn:focus{
      color: #1DBC86;
      border-color: #1DBC86;
    }
    a{
      color: #1DBC86;
      &:hover{
        text-decoration: underline;
      }
    }
    .ant-select-single:not(.ant-select-customize-input):hover .ant-select-selector, .ant-select-single:not(.ant-select-customize-input):focus .ant-select-selector{
      border-color: #1DBC86;
    }
  }
}

@primary-color: #0f8605;@border-radius-base: 2px;
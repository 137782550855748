.list {
  padding: 0 20px 20px 12px;
  .item {
    cursor: pointer;
    .title {
      padding: 9px 0 0 12px;
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #ddd;
      }
      &:hover{
        color: #1A9825;
        &:before{
          width: 8px;
          height: 8px;
          bottom: 1px;
          background: #1BB829;
        }
      }

    }

  }

}
@primary-color: #0f8605;@border-radius-base: 2px;
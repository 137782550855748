.item {
  width: 204px;
  height: 248px;
  font-size: 13px;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 20px;
  background: #fff;
  > img {
    width: 100%;
    height: 133px;
    display: block;
  }

  .box {
    height: 115px;
    position: relative;
    padding: 15px 10px;
    font-size: 14px;
    color: #333;

    .title {
      line-height: 20px;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
        &:hover {
          color: #1D8526;
        }
      }
      .footer {
        position: absolute;
        bottom: 10px;
        left: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 10px;
        color: #BFBFBF;
        .text{
          margin-left: 3px;
        }
      }
    }

}

@primary-color: #0f8605;@border-radius-base: 2px;
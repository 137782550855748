
.box{
  display: flex;
  justify-content: space-between;
}


.largeInfo{
  position: relative;
  .largeTitle{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-size: 16px;
    padding: 0 10px;
    color: #fff;
    background: rgba(0,0,0,.6);
    line-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
  .banner{
    display: block;
    width: 100%;
    height: 248px;
    cursor: pointer;
  }
}

.newList{
  margin-top: 20px;
  background: #fff;
}
.items{
    display: flex;
    flex-wrap: wrap;
    max-height: 516px;
    width: 652px;
    overflow: hidden;
    > div:nth-of-type(3n+3){
      margin-right: 0;
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;
.cardbox{
    height: auto;
    width: 100%;
    .title{
        //border-bottom: 1px solid #F3F0F3;
        font-size: 24px;
        line-height: 24px;
        font-weight: bold;
        color:#000;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        margin-top: 40px;
        margin-bottom: 15px;

      .ext{
            font-weight: normal;
            line-height: 14px;
            font-size: 14px;
            margin-right: 20px;

            i{
                margin-right: 20px;
                font-style: normal;
                color:#666;
                position:relative;
                cursor: pointer;
                &:hover{
                    color:#1D8526;
                }
                &:last-child{
                    margin-right: 0;
                    &:after{
                        visibility: hidden;
                    }
                }
            }
        }
        .moreBtn{
            cursor: pointer;
            color: #666;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
          &:hover{
            color: #1D8526;
          }
        }
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;
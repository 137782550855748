.box{
  display: flex;

  .projectList{
    height: 248px;
    display: flex;
    > div{
      margin-bottom: 0;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .right{
    flex: 1;
    height: 500px;
  }
  .largeInfo{
    position: relative;
    .largeTitle{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      font-size: 16px;
      padding: 0 10px;
      color: #fff;
      background: rgba(0,0,0,.6);
      line-height: 36px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }
    .banner{
      display: block;
      width: 100%;
      height: 248px;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }

}
@primary-color: #0f8605;@border-radius-base: 2px;
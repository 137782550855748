.box{
  display: flex;
  background: #fff;
  .tabs{
    > div {
      width: 119px;
      height: 104px;
      color: #fff;
      font-size: 14px;
      font-weight: bold ;
      text-align: center;
      padding-top: 22px;
      border-left: 2px solid #1A9825;
      margin-bottom: 1px;
      background: #1A9825;
      cursor: pointer;
      >img{
        display: block;
        margin: 0 auto 12px auto;
      }
      &.active{
        color: #1A9825;
        background: #fff;
      }
    }

  }
  .content{
    width: 376px;
    padding-top: 40px;
    padding-right: 48px;

    .title{
      color: #000;
      font-weight: bold;
      line-height: 14px;
      margin-bottom: 20px;
      text-align: center;
      margin-left: 48px;

    }


  }
  .btns{
    margin-left: 48px;
    text-align: center;
    .submitBtn{
      width: 100%;
      height: 40px;
      font-size: 18px;
      //font-weight: bold;
      color:#fff;
      background: #FFAC34;
      border-radius: 5px;
      border:0;
      margin-top: 10px;
      &:hover{
        background: #F78F39;
      }
    }

  }
  :global{
    .ant-form-item{
      margin-bottom: 20px;
    }
    .ant-form-item-label > label{
      color: #333;
    }
    .ant-input-affix-wrapper-lg{
      font-size: 14px;
    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
.list{
    overflow: hidden;
    cursor: pointer;
    line-height: 40px;
  padding: 0 5px ;
  margin-top: 40px;
  background: #fff;
    .item{
      display: flex;
      align-items: center;
        font-size: 14px;
        .time{
          display: inline-block;
          color: #999;
          vertical-align: center;
        }
        .title{
            display: inline-block;
            flex: 1;
            padding-left: 12px;
            overflow: hidden;
            position:relative;
            color:#000;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-left: 2px;
            &:before{
                position:absolute;
                content: '';
                left: 0;
                top:50%;
                margin-top: -2px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background:#999;
            }
          &:hover{
            color: #1D8526;
          }
        }

    }
}
@primary-color: #0f8605;@border-radius-base: 2px;
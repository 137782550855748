.steps{
  position: relative;
  border-radius: 2px;
  padding: 22px 0;
  background: inherit;
  display: flex;
  justify-content: space-around;
  .step{
    text-align: center;
    z-index: 100;
      .title {
        font-size: 16px;
        line-height: 20px;
        color: #666;
      }
      .title.current{
        color: #1BAE96;
      }
      img{
        background: #fff;
      }
  }
  .bg{
    position: absolute;
    top: 34px;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, #ccc 0%, #ccc 50%, transparent 50%);
    background-size: 8px 1px;
    background-repeat: repeat-x;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
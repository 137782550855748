.wrap{
    display: flex;
    align-items: center;
    //justify-content:space-between;
    height: 110px;
    .logo{
        cursor: pointer;
      img{
        width: auto;
        height: 54px;
      }
    }
    .searchWrap{
      position: relative;
      margin-left: 105px;
    }
    .tags{
      position: absolute;
      bottom: -26px;
      left: 0;
      color: #ccc;
      font-size: 12px;
      line-height: 26px;
      span{
        cursor: pointer;
        margin-right: 20px;
        &:hover{
          color: #1D8526;
        }
      }
    }
  .mobile{
    display: flex;
    margin-left: 100px;
    align-items: center;
    color: #999;
    img{
      width: 31px;
      height: 31px;
      margin-right: 10px;
    }
    span{
      color: #333;
      font-size: 18px;
      line-height: 20px;
      font-weight: bold;
    }
  }
    .ext{
        cursor: pointer;
        //border-radius: 4px;
        //border:1px solid #efc107;
        font-size: 14px;
        border:1px solid #ff9c45;
        color:#ff9c45;
        background: #fff;
        padding: 0 15px;
        line-height: 30px;
        font-weight: normal;
        .iconbianji{
            margin-right: 8px;
        }
    }
    .ext:hover{
        background: #ff9c45;
        color: #fff;
    }
}

@primary-color: #0f8605;@border-radius-base: 2px;
.swiper{
  width: 480px;
  height: 326px;
  img{
    width: 100%;
    height: 326px;
  }

}
.swiperBox{
  display: flex;
  justify-content: space-between;
  .right{
    img{
      width: 248px;
      height: 155px;
      display: block;
      margin-bottom: 16px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
.card {
  border:1px solid rgba(246, 246, 246, 1);
  border-radius: 5px;
  .title{
    background: #1D8526;
    line-height: 42px;
    font-size: 16px;
    padding: 0 19px;
    color: #fff;
  }
  .list{
    padding: 10px 15px 15px 15px ;
    .item {
      position: relative;
      font-size: 14px;
      color: #333;
      cursor: pointer;
      margin-bottom: 10px;
      .banner{
        display: none;
        width: 100%;
        height: 112px;
        margin-bottom: 10px;
      }
      .name{
        padding-left: 12px;
        &:before{
          position:absolute;
          content: "";
          width: 5px;
          height: 5px;
          border-radius:50%;
          background:#DDD;
          top: 50%;
          left: 0;
          transform: translate(0%, -50%);

        }
      }

      &:hover{
        .name{
          text-decoration: underline;
          &:before{
            width: 8px;
            height: 8px;
            bottom: 1px;
            background: #1BB829;
          }
        }


      }

      //&:hover{
      //  color: #1FAE94;
      //}
      .index{
        width: 19px;
        height: 19px;
        line-height: 19px;
        font-size: 12px;
        text-align: center;
        color: #bbb;
        background: #ECFAF8;
        margin-right: 15px;

      }
      &:nth-child(-n + 3) .index{
        color: #fff;
        background: #20AD94;
      }
      .name {
        position: relative;
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 20px;
        margin-right: 10px;

      }


    }
    .active{
      .banner{
        display: block;
      }
    }
  }

}
@primary-color: #0f8605;@border-radius-base: 2px;
.box{
  display: flex;
  .projectList{
    height: 248px;
    display: flex;
    margin-top: 20px;
    > div{
      margin-bottom: 0;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .right{
      flex: 1;
      height: 500px;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
.box{
  display: flex;
  justify-content: space-between;
  .left{
    width: 654px;
    .infoBox{
      width: 100%;
      height: 107px;
      font-size: 14px;
      color: #333;
      background: #fff;
      padding: 22px;
      margin-bottom: 20px;
      &>div{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        cursor: pointer;
        &:hover{
          color: #1D8526;
        }
      }
      & div:nth-of-type(1){
        font-size: 18px;
        margin-bottom: 14px;
      }
      & div:nth-of-type(2){
        line-height: 15px;
        display: inline-block;
        text-align: right;
        width: 50%;
        padding-right: 10px;
        border-right: 1px solid #333;

      }
      & div:nth-of-type(3){
        line-height: 15px;
        display: inline-block;
        text-align: left;
        width: 50%;
        padding-left: 11px;
      }
    }
    .infoBottom{
      display: flex;

      .info{
        width: 321px;
        height: 245px;
        overflow: hidden;
        padding: 22px 16px ;
        background: #fff;

        div{
          color: #333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
          margin-bottom: 13px;

          &:hover{
            color: #1D8526;
          }
        }
      }
      .largeInfo{
        position: relative;
        .largeTitle{
          width: 312px;
          font-size: 16px;
          padding: 0 10px;
          color: #fff;
          background: rgba(0,0,0,.8);
          line-height: 36px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          &:hover{
            text-decoration: underline;
          }
        }
        .banner{
          display: block;
          width: 312px;
          height: 209px;
          margin-right: 20px;
          cursor: pointer;
          object-fit:cover
        }
      }
    }
  }


  .projectList{
    height: 248px;
    display: flex;
    > div{
      margin-bottom: 0;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .right{
    flex: none;
    width: 428px;
  }
}

@primary-color: #0f8605;@border-radius-base: 2px;
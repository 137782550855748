.list {
  display: flex;

  .before {
    width: 240px;
    font-size: 16px;
    margin: 0 25px 0 0;

    .item {
      display: flex;
      margin-top: 20px;
      overflow: hidden;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      .imgWrap {
        position: relative;

        .img {
          width: 100%;
          height: 148px;
          font-size: 0;
          display: block;
        }

        .imgInnerTitle {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          color: #fff;
          line-height: 32px;
          font-size: 16px;
          padding: 0 7px;
          background: rgba(0, 0, 0, 0.6);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:hover{
            color: #1FAE94;
          }

        }
      }
    }
  }

  .after {
      flex: 1;
    margin-top: 15px;
    .item {
      height: 80px;
      display: flex;
      font-size: 14px;
      padding: 5px 0 5px;
      position: relative;
      min-height: 60px;
      .border{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(to right, #ccc 0%, #ccc 50%, transparent 50%);
        background-size: 8px 1px;
        background-repeat: repeat-x;
      }

      //&:last-child{
      //  border-bottom: 0;
      //}
      .descwrap {
        width: 100%;
        padding-right: 10px;
        .titleWrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          color: #000;
          cursor: pointer;
          .title{
            overflow:hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:hover{
              color: #1FAE94;
            }
          }
          .time {
            font-size: 14px;
            color: #B6B5B5;
          }
        }

        .desc {
          .descSuffix{
            display: inline-block;
            color: #1FAE94;
            float: right;
            cursor: pointer;
            &:hover{
              color: #FBB984;
            }
          }
        }
      }

      //&:after {
      //  content: '';
      //  position: absolute;
      //  width: 100%;
      //  height: 1px;
      //  left: 0;
      //  bottom: 0;
      //  background: #eee;
      //}

      &.noLine {
        &:after {
          visibility: hidden;
        }
      }


    }
  }

}
@primary-color: #0f8605;@border-radius-base: 2px;
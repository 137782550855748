.box{
  display: flex;
  flex-wrap: wrap;
  max-height: 516px;
  overflow: hidden;

  > div{
    //margin-bottom: 0;
    &:nth-of-type(4){
      margin-right: 0;
    }
    &:nth-of-type(9){
      margin-right: 0;
    }
  }
  .right{
    flex: 1;
    height: 500px;
  }
  .largeInfo{
    position: relative;
    margin-right: 20px;
    margin-bottom: 20px;
    .largeTitle{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      font-size: 16px;
      padding: 0 10px;
      color: #fff;
      background: rgba(0,0,0,.6);
      line-height: 36px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }
    .banner{
      display: block;
      width: 428px;
      height: 248px;
      cursor: pointer;
    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
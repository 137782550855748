
.banner{
  position: relative;
  img{
    height: auto;
  }
}
.breadcrumb{
  background: #f5f5f5;
}
.tabs{
  display: flex;
  border-bottom: 2px solid #0F8606;
  margin-top: 20px;
  .tab{
    width: 125px;
    line-height: 41px;
    background: #0F8606;
    text-align: center;
    color: #fff;
    font-size: 20px;
  }
}
.bodyWarp{
  margin-bottom: 10px;
  background: #fff;
  .listWrap{
    padding: 30px 25px;
    border: 1px solid #ccc;
    .item{
      margin-bottom: 12px;
      font-size: 16px;
      .title{
        cursor: pointer;
      }
      &:hover{
        .title{
          color: #0F8606;
        }
      }
      .time{
        display: inline-block;
        margin-left: 20px;
        color: #aaa;
      }
    }
  }

}





.content{

}



@primary-color: #0f8605;@border-radius-base: 2px;
.box{
  height: auto;
  width: 100%;
  padding-top: 56px;
  padding-bottom: 50px;

  .title{
    font-size: 22px;
    line-height: 22px;
    color:#333;
    text-align: center;
    margin-bottom: 15px;
    span{
      position: relative;
      &:after{
        content:'';
        position: absolute;
        left: -32px;
        top: 50%;
        width: 28px;
        height: 20px;
        transform: translate(-50%,-50%);
        background:  url("~aliasimgurl/assess_after.png") ;
        background-size: 100% 100%;
      }
      &:before{
        content:'';
        position: absolute;
        right: -45px;
        top: 25%;
        width: 28px;
        height: 20px;
        transform: rotate(180deg);
        background:  url("~aliasimgurl/assess_after.png") ;
        background-size: 100% 100%;
      }
    }

  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
.footer{
    background: #fff;
    .LA{
      padding: 25px 0;
      margin-bottom: 10px;
      .title{
        font-size: 24px;
        line-height: 24px;
        color: #000;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .linkBox{
        a{
          display: inline-block;
          font-size: 14px;
          color: #999;
          line-height: 14px;
          padding-right: 19px;
          border-right: 1px solid #999;
          margin-right: 20px;
          margin-bottom: 13px;
          &:hover{
            text-decoration: underline;
          }
        }
        a:last-child{
          border: 0;
          padding: 0;
          margin: 0;
        }
      }

    }
    .ext{
      background: #464646;
      color: #fff;
      .content{
        display: flex;
        .extRight{
          width: 630px;
          display: flex;
          justify-content: space-between;
          padding-right: 40px;
          border-right: 1px solid #fff;
          margin: 36px 0;
          .title{
            font-size:16px;
            line-height: 14px;
            color: #fff;
            padding-bottom: 20px;
          }
          .row{
            a{
              display: inline-block;
              width: 78px;
              line-height: 30px;
              color: #eee;
              &:hover{
                color: #1A9825;
                text-decoration: underline;
              }
            }
          }
        }
        .extLeft{
          width: 470px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 53px;
          .contact{
            font-size:14px;
            line-height: 26px;
          }
          .QRcode{
            img{
              width: 108px;
              height: 108px;
              display: block;
            }
            .text{
              font-size: 12px;
              line-height: 12px;
              text-align: center;
              margin-top: 10px;
            }
          }
        }
      }


    }

    .footerBottom{

        background: #000;
        height: 124px;
        padding-top: 28px;
        color:#bbb;
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 26px;
      a{
        display:inline-block;
        text-decoration:none;
        height:20px;
        line-height:20px;
        color: #bbb;
        img{
          display: inline-block;
          margin-right: 5px;
        }
        span{
          vertical-align: middle;
        }
      }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
.cardbox{
    height: 270px;
    width: 100%;
    background: #fff;
    border: 1px solid #DDDDDD;
    border-right: 0;
    .title{
        //border-bottom: 1px solid #F3F0F3;
      font-size: 16px;
      line-height: 53px;
      color: #000;
      text-align: center;
      background: #EFEFEF;
    }
  .children{
    padding: 22px 22px 0 40px;
  }
  p{
    position: relative;
    font-size: 16px;
    line-height: 30px;
    color: #333;
    padding-left: 26px;
    margin-bottom: 0;
    //background: url("~aliasimgurl/icon_tick.png");
    //background-size: 100% 100%;
    &:before {
      position:absolute;
      content: "";
      width: 15px;
      height: 15px;
      background: url("~aliasimgurl/icon_tick.png");
      background-size: 100% 100%;
      top: 50%;
      left: 8px;
      transform: translate(-50%,-50%);
    }
  }
  &:hover{
    border-color: #138A27;
    border-right: 1px #138A27 solid;
    transform: scale(1.1);
    z-index: 100;
    .title{
      color: #fff;
      background: #138A27;
    }
  }
  &:last-child{
    border-right: 1px #ddd solid;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
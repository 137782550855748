.box{


  .list{
    padding: 0 20px 0 0;
    max-height: 600px;
    overflow-y: auto;
    //position: relative;
    .header{
      height: 36px;
      font-size: 16px;
      display: flex;
      border-bottom: 1px solid #eee;

      .name{
        flex: 1;
        font-weight: normal;
        text-align: center;
        cursor: default;
        &:hover{
          color: inherit;
        }
      }
      .status{
        width: 140px;
        color: inherit;
        text-align: center;
      }
      .time{
        width: 140px;
        color: inherit;
        line-height: 26px;
        text-align: center;
      }
      .operation{
        width: 160px;
        text-align: center;
      }
    }
    .row{
      height: 70px;
      display: flex;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px dashed #eee;
      font-size: 14px;
      .name {
        flex: 1;
        font-size: 16px;
        color: #333;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2 ;
        text-overflow:ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        &:hover {
          color: #1D8526;

        }

      }
      .status{
        width: 140px;
        color: #333;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
      }
      .time{
        width: 140px;
        color: #999;
        line-height: 26px;
        text-align: center;
      }
      .operation{
        width: 160px;
        color: #999;
        line-height: 26px;
        text-align: center;
        .btn{
          width: 83px;
          line-height: 26px;
          font-size: 14px;
          text-align: center;
          cursor: pointer;
          border-radius: 5px;
          border: 1px #DDDDDD solid;
          color: #DDDDDD;
          margin: auto;
          &:hover{
            border-color: #5AB2A2;
            background: #5AB2A2;
            color: #fff;
          }
        }

        &:hover {
          .btns {
            display: block;

          }
        }
      }
    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
.card {
  border:1px solid rgba(246, 246, 246, 1);
  border-radius: 5px;
  .title{
    background: #E0DFF5;
    text-align: center;
    line-height: 42px;
    font-size: 16px;
    padding: 0 19px;
    color: #333;
  }
  .list{
    padding: 0 15px;
    background: #fff;
    .item {
      position: relative;
      display: flex;

      font-size: 14px;
      color: #333;
      cursor: pointer;
      padding-left: 20px;

      border-bottom: 1px solid #F5F5F5;
      &:last-child{
        border:0;
      }
      &:before{
        position:absolute;
        content: "";
        width: 5px;
        height: 5px;
        border-radius:50%;
        background:#DDD;
        top: 50%;
        left: 8px;
        transform: translate(-50%,-50%);

      }
      &:hover{
        &:before{
          width: 8px;
          height: 8px;
          background: #E0DFF5;
        }
      }
      //&:hover{
      //  color: #1FAE94;
      //}
      .index{
        width: 19px;
        height: 19px;
        line-height: 19px;
        font-size: 12px;
        text-align: center;
        color: #bbb;
        background: #ECFAF8;
        margin-right: 15px;

      }
      &:nth-child(-n + 3) .index{
        color: #fff;
        background: #20AD94;
      }
      .name {
        position: relative;
        flex: 1;
        color: #666;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 47px;
        margin-right: 10px;
        &:hover{
          text-decoration: underline;
        }
      }


    }


  }

}
@primary-color: #0f8605;@border-radius-base: 2px;
.box {
  margin-top: 30px;

  .list{
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    .enum{
      width: 173px;
      height: 300px;
      color: #333;
      padding: 33px 14px ;
      background: #fff;
      box-shadow: 0px 5px 10px 0px rgba(221, 221, 221, 0.2);
      border-radius: 10px;
      margin-right: 20px;
      margin-bottom: 16px;
      cursor: pointer;
      .avatar{
        text-align: center;
        img{
          width: 87px;
          height: 87px;
          background: #444444;
          border-radius: 50%;
        }
      }
      .title{
        //border-bottom: 1px solid #F3F0F3;
        color: #333;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 10px;
      }
      .subtitle{
        text-align: center;
        font-size: 14px;
        color: #666;
      }
      .content{
        font-size: 12px;
        color: #888;
        line-height: 20px;
        margin-top: 8px;
      }
      &:hover{
        color: #fff;
        background: linear-gradient(-63deg, #7B87FA, #4689EB);
        box-shadow: 0px 6px 12px 0px rgba(123, 135, 250, 0.34);
        .title,.subtitle,.content{
          color: #fff;
        }
      }
    }
  }
  .moreBtn{
    width: 170px;
    line-height: 38px;
    text-align: center;
    font-size: 16px;
    color:#fff;
    padding: 1px;

    background: linear-gradient(-63deg, #7B87FA, #4689EB);
    box-shadow: 0px 6px 12px 0px rgba(123, 135, 250, 0.34);
    border-radius: 19px;
    margin: 34px auto 0 auto;
    cursor: pointer;
    &:hover{
      padding: 0;
      color: #645ecc;
      border: 1px solid  #645ecc;
      background: inherit;
    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
html,
body,
#root {
    font-family: "Microsoft YaHei,-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'";
    min-width: 1100px;
    min-height: 100%;
}
#root{
    display: flex;
    flex-direction: column;
}

body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.left {
    float: left;
}

.right {
    float: right;
}

.clearfix:after {
    content: "";
    clear: both;
    display: block;
    visibility: hidden;
}

* html .clearfix {
    zoom: 1;
}

*_html .clearfix {
    zoom: 1;
}
.container {
    display: flex;
    flex-direction: column;
    width: 1100px;
    margin: 0 auto;
}

.line1{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.line2{word-break:break-all;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;}
.line3{word-break:break-all;display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;overflow:hidden;}



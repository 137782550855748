
.bodyWarp{
  display: flex;
  .leftWarp{
    width: 810px;
    margin-right: 30px;
    .searchWrap{
      background: #EEEEEE;
      padding: 0 20px;
      margin-bottom: 20px;
      .searchItem{
        margin: 0;
        padding:14px 0 9px 0;
        border-bottom: 1px dotted #ddd;
        &:last-child{
          border-bottom:none;
        }
      }
    }

    .listWrap{
      position: relative;
      flex:1;
      background:#fff;
      margin-bottom: 20px;
      .tabsBox{
        position: sticky;
        top: 0;
        background:#fff;
        z-index: 1000;
        .tabs{

          display: flex;
          justify-content: space-between;
          font-size: 16px;
          padding: 15px 0;
          border-bottom: 1px solid #DDDDDD;
          .tab{
            position: relative;
            padding: 0 18px;
            cursor: pointer;
          }
          .active{
            font-weight: bold;
            color: #1D8526;
            .border{
              position: absolute;
              left: 50%;
              bottom: -3px;
              height: 2px;
              border-radius: 1px;
              background: #1D8526;
              width: 20px;
              transform: translate(-50%,0);
            }
          }
        }

      }
      .title{
        padding-bottom: 20px;
        border-bottom: 1px solid #ddd;
        i{
          font-style: normal;
          margin: 0 10px;
          font-size: 16px;
          cursor: pointer;
          &.active{
            color: #1FAE94;
          }
        }
      }
    }

  }
  .rightWarp{
    flex: none;
    width: 330px;
    .headline{
      height: 317px;
      position: relative;
      border: 1px solid #ddd;
      padding: 25px 16px 0 16px;
      margin-top: 10px;
      .img{
        position: absolute;
        top: -10px;
        left: -12px;
        height: 44px;
      }
      .header{
        position: absolute;
        top: -10px;
        left: -12px;
        line-height: 35px;
        font-size: 20px;
        color: #fff;
        //font-weight: bold;
        width: 95px;
        text-align: center;
      }
      .item{
        font-size: 16px;
        line-height: 24px;
        padding: 13px 0;
        color: #000;
        border-bottom: 1px dashed #ddd;
        cursor: pointer;
        &:last-child{
          border: 0
        }
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}




.viewWrap{
  padding: 30px 0;
  .titleInfo{
    padding: 0 60px;
    text-align: center;
    border-bottom: 1px solid  #eee;
    .viewTitle{
      font-size: 18px;
      color:#000;
      font-weight: bold;
    }
    p{
      font-size: 14px;
      color:#a5a4a4;
      margin-top: 10px;
      .text{
        vertical-align: middle;
      }
      > span{
        line-height: 20px;
        margin-right: 35px;
        &:last-child{
          margin-right: 0;
        }
      }

    }
    .check{
      color: #067562;
    }
  }
  .desInfo{
    padding: 20px;
    font-size: 14px;
    line-height: 24px;
    color: #000;
    box-sizing: border-box;
    text-align: justify;
    a{
      color: #0681d0;
      text-decoration: underline;
    }
    img{
      max-width: 100%;
    }
  }

  :global{
    .anticon{
      font-size: 16px;
      margin-right: 5px;
      vertical-align: middle;
    }
  }
}





@primary-color: #0f8605;@border-radius-base: 2px;
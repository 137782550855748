.card{
  flex:1;
  display: flex;
  flex-direction: column;
  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333333;
    font-weight: bold;
    line-height: 54px;
    padding-left: 20px;
    font-size: 16px;
    border-bottom: 1px solid #F5F5F5;
    .name{
      position: relative;
      .border{
        position: absolute;
        bottom: 0;
        height: 2px;
        width: calc(100% - 20px );
        margin: 0 10px;
        background:  #017462;
        border-radius: 1px;
      }
    }
    .extra{
    }
  }

  .cardBody{
    padding: 30px;
    background:#fff;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
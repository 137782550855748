.industry{
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;

    button{
      height: 28px;
      font-size: 14px;
      color: #B7B6B6;
      text-align: center !important;
      border-color: #ddd;
      border-radius: 5px;
      padding: 0 14px;
      margin-right: 12px
    ;
    }
    button.check {
      color: #32CD9B;
      border-color:  #32CD9B;
    }
    :global{
      .ant-btn:hover{
        color: #32CD9B;
        border-color:  #32CD9B;
      }
    }

}
@primary-color: #0f8605;@border-radius-base: 2px;
.modal{
    text-align: center;
  font-size: 16px;
  color: #666;
  line-height: 36px;
  .btn{
    width: 86px;
    line-height: 30px;
    color: #fff;
    text-align: center;
    background: #6DD56E;
    border-radius: 5px;
    margin:10px auto;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;


.list{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .item {
    width: 253px;
    font-size: 13px;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 10px;
    > img {
      width: 100%;
      height: 168px;
      display: block;
    }

    .box {
      position: relative;
      padding: 10px 8px;
      font-size: 14px;
      color: #333;

      .title {
        line-height: 20px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        &:hover {
          color: #1D8526;
        }
      }
      .footer {
        position: absolute;
        bottom: 10px;
        left: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 10px;
      }
    }

  }
  .item:nth-of-type(4n+4){
    margin-right: 0;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;
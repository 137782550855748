.formBody{
    margin-bottom: 20px;
    h3{
        text-align: center;
        font-size: 24px;
        margin: 30px auto;
    }
    .editForm{
        width: 550px;
        margin: 50px auto;
        .form{
            >div{
                margin-bottom: 18px;
            }
        }
        .itemWithBtn{
            display: flex;
            .codeWrap{
                flex:1;
            }
            .btn{
                margin-left: 10px;
                width: 110px;
                *{
                    width: 100%;
                }
                img{
                    height: 40px;
                    border:1px solid #ddd;
                    border-radius:3px;
                    cursor: pointer;
                }
            }
        }
        .btnWrap{
            text-align: center;
            button{

            }
        }
        .radioGroup{
            display: flex;
            .radio{
                // border:1px solid #f3f5f7;
                /* label{
                    padding: 9px 20px;
                    margin: 0;
                    &:global(.ant-radio-wrapper-checked){
                        background: #fbc330;
                        color:#fff;
                    }
                } */
            }
        }
    }
  :global{
    .ant-btn-primary{
      background: #009f8f;
      border-color: #009f8f;
    }
    .ant-btn-primary:hover, .ant-btn-primary:focus{
      background: #369b81;
      border-color: #369b81;
    }
  }
}


.stepWrap{
    width: 700px;
    margin:auto;
  .steps{
    margin-top: 10px;
    margin-bottom: 40px;
  }
   :global{
     .ant-steps-item-finish .ant-steps-item-icon{
       border-color: #5AB2A2;
     }
     .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
       color:  #5AB2A2
     }
     .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
        background: #5AB2A2;
     }
     .ant-steps-item-process .ant-steps-item-icon{
       border-color: #5AB2A2;
       background: #5AB2A2;
     }
   }
}

.tips{
    text-align: center;
    color:#5AB2A2;
    font-weight: bold;
    margin-top: 20px;
    font-size: 16px;
}
.help{
  text-align: center;
  color:#ccc;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 20px;
}
.reject{
  text-align: center;
  color:#FF3434;
  font-weight: bold;
  margin: 20px 0;
  font-size: 16px;
}
.success{
  text-align: center;
  color:#5AB2A2;
  font-weight: bold;
  margin: 100px 0 20px 0;
  font-size: 16px;
}
.authAwait{
  width: 56px;
  height: 60px;
}
.authSuccess{
  width: 60px;
  height: 60px;
}
.authReject{
  width: 60px;
  height: 60px;
}

.authRes{
    margin-bottom: 160px;
    .item{
      display: flex;
      font-size: 14px;
      color: #666666;
      font-weight: bold;
      margin: 10px 0;
      .label{
        flex:4;
        text-align: right;
        padding-right:10px;
      }
      .content{
        flex:5;
        text-align: left;
      }
    }
    .toDoBtn{
      text-align: center;
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;
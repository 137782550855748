.statusWarp{

  .typeBox{
    display: flex;
    margin-bottom: 10px;
    &>div{
      padding: 0 10px;
      line-height: 24px;
      font-size: 14px;
      color: #666;
      text-align: center;
      cursor: pointer;
      margin-right: 10px;
      border-radius: 5px;
    }
    .label{
      color: #000000;

    }
    .active{
      background: #5AB2A2;
      font-weight: bold;
      color: #fff;
    }
  }

}

.industryWarp{
  height: 100%;
  .typeBox{
    display: flex;
    margin-bottom: 10px;
    &>div{
      padding: 0 15px;
      line-height: 28px;
      font-size: 14px;
      color: #ccc;
      text-align: center;
      cursor: pointer;
      margin-right: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;

    }
    .active{
      color: #5AB2A2;
      border-color:  #5AB2A2;
    }
  }

}
@primary-color: #0f8605;@border-radius-base: 2px;
.list{
  height: 100%;
  width: 100%;
  padding: 0 19px;
  background: #fff;
  .item{
    display: flex;
    position: relative;
    line-height: 43px;
    color:#333;
    border-bottom: dashed 1px #E0E0E0;
    &:last-child{
      border: 0;
    }
    .title,.type,.area,.regin,.biddingType{
      flex:1;
      overflow: hidden;
      text-align: center;
    }
    .title{
      flex:2;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;

      &:hover{
        color: #1D8526;
      }

    }
    .time{
      text-align: right;
      width: 85px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.tableTitle{
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid #ddd;
      padding-top: 8px;
      &>div:hover{
        color: #000;
        cursor: default;
      }
    }
  }
  :global{
    .ant-statistic-content{
      font-size: 13px;

    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;